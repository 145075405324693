.contacto-container{
    display: flex;
    font-family: 'Montserrat', sans-serif;
    @media screen and (max-width: 992px) {
        &{
            flex-direction: column;
        }
    }
    .contacto-formulario{
        width: 50%;
        box-sizing: border-box;
        @media screen and (max-width: 992px) {
            &{
                width: 100%;
            }
        }
        .⼓{
            width: 65%;
            margin: 4.6em auto 0 auto;
            @media screen and (max-width: 992px) {
                &{
                    width: 80%;
                }
            }
            h3{
                font-family: 'Montserrat', sans-serif;
                font-weight: 900;
                font-size: 1.5em;
                letter-spacing: 0.5px;
                color: #2E3191;
                @media screen and (max-width: 992px) {
                    &{
                        text-align: center;
                    }
                }
            }
            h4{
                font-family: 'Montserrat', sans-serif;
                font-weight: 700;
                font-size: 1.5em;
                letter-spacing: 0.5px;
                color: #2E3191;
                @media screen and (max-width: 992px) {
                    &{
                        text-align: center;
                    }
                }
            }
            p{
                font-family: 'Montserrat', sans-serif;
                font-weight: 500;
                font-size: 1.1em;
                letter-spacing: 0.5px;
                color: #F37021;
                margin: 0 0 0 4.3em;
                @media screen and (max-width: 992px) {
                    &{
                        text-align: center;
                        margin: 0;
                    }
                }
            }
    
            form{
                font-family: 'Montserrat', sans-serif;
                font-weight: 500;
                font-size: 1em;
                letter-spacing: 0.5px;
                margin: 3em 0;
                div{
                    display: flex;
                    justify-content: space-between;
                    margin: 20px 0;
                }
                .select-wrapper{
                    position: relative;
                    width: 49%;
                    margin: 0;
                    &::before{
                        content: url("./../../assets/img/Polygon.svg");
                        position: absolute;
                        right: 10%;
                        top: 50%;
                        transform: translateY(-50%);
                        cursor: pointer;
                        z-index: -10;
                    }
                }
                input, select{
                    border: 2px solid #E5E5E5;
                    border-radius: 30px;
                    outline: none;
                    height: 36px;
                    padding-left: 20px;
                    appearance: none;
                    box-sizing: border-box;
                }
                input::placeholder, select::placeholder, textarea::placeholder{
                    font-family: 'Montserrat', sans-serif;
                    font-weight: 500;
                    font-size: 1em;
                    letter-spacing: 0.5px;
                    color: #3A3A44;
                }
                input[name="nombre"]{
                    width: 100%;
                }
                input[name="cel"]{
                    width: 49%;
                }
                select{
                    width: 100%;
                    padding-right: 20px;
                    background-color: transparent;
                    cursor: pointer;
                }
                textarea{
                    font-family: inherit;
                    border: 2px solid #E5E5E5;
                    resize: none;
                    outline: none;
                    padding: 20px 0 0 20px;
                    width: 100%;
                    box-sizing: border-box;
                    appearance: none;
                }
                button{
                    width: 100%;
                    color: white;
                    background: #2E3191;
                    border: none;
                    border-radius: 30px;
                    height: 36px;
                    margin-top: 15px;
                    cursor: pointer;
                    transition: all .4s ease-in-out;
                    &:hover{
                        box-shadow: 3px 3px 6px 0px rgb(153, 148, 148);
                        transform: translateY(2px);
                        transform: translatex(-2px);
                    }
                }
            }

        }
    }

    .contacto-info{
        color: white;
        width: 50%;
        @media screen and (max-width: 992px) {
            &{
                width: 100%;
            }
        }
        a{
            text-decoration: none;
            color: white;
        }
        .contacto-datos{
            background-color: #2E3191;
            aspect-ratio: 16/9;
            padding: 5vw 60px 0 78px;
            box-sizing: border-box;
            @media screen and (max-width: 992px) {
                &{
                    padding: 10vw 0 10vw 0;
                    aspect-ratio: unset;
                }
            }
            h3{
                margin: 0 0 40px 70px;
                font-weight: 700;
                font-size: 1.5em;
                letter-spacing: 0.5px;
                @media screen and (max-width: 992px) {
                    &{
                        text-align: center;
                        margin: 0 0 40px 0;
                        font-size: 1.2em;
                    }
                }
            }
            p{
                margin: 0;
            }
            div{
                display: flex;
                align-items: center;
                @media screen and (max-width: 992px) {
                    &{
                        width: 80%;
                        margin: 0 auto;
                    }
                }
                svg{
                    min-width: 24px;
                    margin-right: 43px;
                }
            }
            & > div + div {
                margin-top: 30px;
            }
        }
        .contacto-mapa{
            width: 100%;
            iframe{
                width: 100%;
                aspect-ratio: 16/9;
                @media screen and (max-width: 992px) {
                    &{
                        aspect-ratio: unset;
                        height: 100vw;
                    }
                }
            }
        }
    }
}