@import "../vars.scss";
.metas-section {
  background-color: #ffffff;
  margin-bottom: 8em;
  @media ($ipad) {
    margin-bottom: 4em;
  }
  .metas-container {
    .first-content {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      h4 {
        font-family: $montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 50px;
        text-align: center;
        letter-spacing: 0.5px;
        color: #2e3191;
        max-width: 60%;
        @media ($ipad) {
          max-width: 80%;
          font-size: 16px;
          line-height: 20px;
        }
      }
      img {
        &.Marcelo-trc-11 {
          @media ($ipad) {
            width: 70%;
          }
        }
      }
    }
    .second-content {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      text-align: center;
      margin: 2em 0em 0em 0em;
      align-items: center;
      height: 25%;
      @media ($ipad) {
        grid-template-columns: repeat(1, 1fr);
      }
      .logotio-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-height: 80%;
        @media ($ipad) {
          margin: 1em;
          display: inline;
        }
        div {
          cursor: pointer;
        }
        img {
          cursor: pointer;
          &.imagesss {
            @media ($ipad) {
              width: 60%;
            }
          }
          &.arrow-down-section {
            margin-top: 1em;
            @media ($ipad) {
              min-height: 40px;
              margin-top: 0.5em;
            }
            @media ($bigcel) {
              min-height: 20px;
              margin-top: 0;
            }
          }
          &.Marcelo-trc-111 {
            margin-top: -0.5em;
            margin-bottom: 1em;
            @media ($ipad) {
              margin-top: 0em;
              margin-bottom: -0.3em;
            }
          }
          &.Marcelo-trc-113 {
            margin-top: -1.9em;
            @media ($ipad) {
              margin-top: 0em;
            }
          }
        }
        //ocultar secciones
        &.ocult-big {
          display: inline;
          @media ($ipad) {
            display: none;
          }
        }
        &.ocult-child {
          display: none;
          @media ($ipad) {
            display: inline;
          }
        }
      }
    }
  }
}
