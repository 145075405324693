@import "../vars.scss";
.trayectoria-container {
  background-color: rgba(242, 240, 240, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  @media ($ipad) {
    padding-bottom: 2em;
  }
  h4 {
    margin-top: 0.1em;
    font-family: $montserrat;
    font-weight: 800;
    font-style: normal;
    font-size: 25px;
    line-height: 30px;
    color: #2e3191;
    @media ($ipad) {
      font-size: 18px;
      line-height: 20px;
      max-width: 80%;
      margin-bottom: 0.6em;
    }
  }

  .linevertical {
    @media ($ipad) {
      display: none;
    }
  }
  // background-color: blue;

  .grid-years {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    text-align: center;
    padding: 0em 2.9em;
    margin-bottom: 1.5em;
    @media ($ipad) {
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: center;
      align-items: center;
    }
    .section {
      @media ($ipad) {
        display: flex;
        align-content: center;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      h3 {
        font-size: 30px;
        font-family: $fira;
        font-weight: 700;
        font-style: normal;
        line-height: 30px;
        color: #f37021;
        margin-bottom: 0.5em;
        @media ($ipad) {
          margin-top: 8px;
        }
      }
      p {
        margin-top: 0em;
        font-size: 12px;
        font-family: $montserrat;
        font-weight: 400;
        font-style: normal;
        line-height: 16px;
        color: #2e3191;
        @media ($ipad) {
          margin-bottom: 10px;
          max-width: 80%;
        }
      }
      .linehorizontal {
        display: none;
        @media ($ipad) {
          display: inline;
        }
      }
    }
  }
}
