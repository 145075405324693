@import "../vars.scss";
.video-container {
  background-color: rgba(242, 240, 240, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  iframe {
    width: 100%;
    height: 26em;
    @media ($bigcel) {
      height: 13em;
    }
  }
  .hw-2 {
    width: 97%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media ($ipad) {
      flex-flow: column;
      width: 100%;
    }
    .video {
      width: 56%;
      margin: 2em 0em 2em 0em;
      z-index: 501;
      @media ($ipad) {
        width: 96%;
        margin: 1em 0em 0em 0em;
      }
    }
    .text-1 {
      width: 36%;
      background-color: #ffffff;
      text-align: center;
      padding: 3.5em 0em;
      margin-left: -3px;
      z-index: 500;
      color: $blueprimary;
      font-family: $montserrat;
      font-weight: 500;
      font-size: 20px;
      line-height: 25px;
      font-style: normal;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border-radius: 0px 20px 20px 0px;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
      @media ($ipad) {
        width: 87.5%;
        margin-left: 0px;
        margin-top: -0.5em;
        font-size: 14px;
        border-radius: 0px 0px 20px 20px;
        line-height: 16px;
        padding: 2.3em 0;
        margin-bottom: 2em;
      }
      img {
        margin-top: 1em;
        @media ($ipad) {
          max-width: 63%;
        }
      }
      .text-2 {
        max-width: 80%;
        text-align: center;
        margin-top: 1em;
      }
      h1 {
        margin: 0px;
        font-size: 1em;
        font-weight: 500;
      }
    }
  }
}
