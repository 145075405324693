@import "../vars.scss";
.mitrabajo-component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 5em;
  .title-component {
    font-family: $montserrat;
    font-style: normal;
    font-weight: 800;
    font-size: 25px;
    line-height: 30px;
    color: #2e3191;
    @media ($ipad) {
      font-size: 18px;
      line-height: 30px;
    }
  }
  .items-component {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 4.5%;
    width: 87%;
    @media ($ipad) {
      grid-template-columns: repeat(1, 1fr);
    }

    .esc-made {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background-color: #ffffff;
      box-shadow: 0px 4px 20px 2px rgba(0, 0, 0, 0.25);
      .title-1 {
        background-color: #2e3191;
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: space-around;
        align-items: center;
        min-height: 116px;
        @media ($ipad) {
          min-height: 80px;
        }
        h4 {
          font-family: $montserrat;
          font-style: normal;
          font-weight: 400;
          font-size: 25px;
          line-height: 30px;
          letter-spacing: 0.5px;
          color: #ffffff;
          margin: 0;
          @media ($ipad) {
            font-size: 18px;
            line-height: 22px;
          }
        }
      }
      .imageee-1 {
        background-image: url("../../assets/img/mitrabajo.png");
        background-size: cover;
        min-height: 303px;
        width: 100%;
        @media ($ipad) {
          min-height: 200px;
          background-position: center;
        }
      }
      .pharagraph {
        min-height: 175px;
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 85%;
        text-align: center;
        @media ($ipad) {
          min-height: 300px;
        }
        p {
          font-family: $montserrat;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 25px;
          letter-spacing: 0.5px;
          color: #2e3191;
          @media ($ipad) {
            font-size: 16px;
            line-height: 20px;
          }
        }
      }
      .imageee-12 {
        background-image: url("../../assets/img/crearmejoresoportunidades.png");
        background-size: cover;
        min-height: 303px;
        width: 100%;
        @media ($ipad) {
          min-height: 200px;
          background-position: center;
        }
      }
    }
  }
}
