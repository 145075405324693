.header-container{
    display: flex;
    margin-top: 67px;
    height: calc(100vh - 67px);
    max-height: 45vw;
    justify-content: center;
    @media screen and (max-width: 600px) {
        &{
            position: relative;
            max-height: none;
        }
    }
    div:nth-child(1){
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        width: 50%;
        padding-left: 8%;
        box-sizing: border-box;
        @media screen and (max-width: 600px) {
            &{
                padding-left: 0;
                max-height: 60%;
            }
        }
        img{
            &:nth-child(1){
                width: 80%;
                @media screen and (max-width: 600px) {
                    &{
                        width: 280px;
                    }
                }
            }
            &:nth-child(2){
                width: 80%;
                @media screen and (max-width: 600px) {
                    &{
                        width: 240px;
                    }
                }
            }
            &:nth-child(3){
                width: 55%;
                @media screen and (max-width: 600px) {
                    &{
                        width: 150px;
                        margin-left: 45%;
                    }
                }
            }
        }
    }
    .marcelo-torres{
        background-image: url('./../../assets/img/Marcelo.png');
        background-position: bottom left;
        background-size: contain;
        background-repeat: no-repeat;
        width: 50%;
        @media screen and (max-width: 600px) {
            &{
                position: absolute;
                bottom: 0;
                background-image: url('./../../assets/img/MarceloMovil.svg');
                background-position: bottom left;
                background-size: contain;
                background-repeat: no-repeat;
                width: 100%;
                min-height: 438px;
            }
        }
    }
}