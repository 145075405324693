main{
    .propuestas-header{
        position: relative;
        min-height: 60vw;
        @media screen and (max-width: 992px) {
            &{
                min-height: unset;
                height: 70vh;
            }
        }
        .propuestas-marcelo-wave{
            @media screen and (max-width: 992px) {
                &{
                    width: 100%;
                    min-height: unset;
                    height: 70vh;
                }
            }
        }
        .propuestas-mas{
            position: absolute;
            top: 16vw;
            right: 2vw;
            width: 16%;
            @media screen and (max-width: 992px) {
                &{
                    top: unset;
                    bottom: 40vw;
                }
            }
        }
        p{
            position: absolute;
            top: 23vw;
            left: 49vw;
            font-family: 'Montserrat', sans-serif;
            font-style: normal;
            font-weight: 900;
            font-size: 5vw;
            letter-spacing: 0.5px;
            color: #2E3191;
            @media screen and (max-width: 992px) {
                &{
                    margin: 0;
                    font-size: 10vw;
                    top: unset;
                    bottom: 20vw;
                    left: 10vw;
                }
            }
        }
        button{
            position: absolute;
            top: 41vw;
            right: 17vw;
            display: flex;
            align-items: center;
            padding: 2vw;
            border-radius: 35px;
            border: none;
            background-color: #F37021;
            font-family: 'Montserrat', sans-serif;
            font-weight: 600;
            font-size: 1.25vw;
            letter-spacing: 0.5px;
            color: white;
            width: 24vw;
            height: 9%;
            outline: none;
            cursor: pointer;
            transition: all .4s ease-in-out;
            @media screen and (max-width: 992px) {
                &{
                    top: unset;
                    bottom: 5vw;
                    left: 8vw;
                    width: 82vw;
                    font-size: 4.7vw;
                    padding: 0 5vw;
                }
            }
            &:hover{
                box-shadow: 3px 3px 6px 0px rgb(153, 148, 148);
                transform: translateY(2px);
                transform: translatex(-2px);
            }
            img{
                margin-left: 2vw;
                @media screen and (max-width: 992px) {
                    &{
                        margin-left: 4.4vw;
                    }
                }
            }
        }
    }

    .propuestas-propuestas{
        display: flex;
        flex-direction: column;
        margin: 30px 0 0 0;
        .propuestas-propuesta{
            display: flex;
            width: 92%;
            margin: 0 auto 4.68em auto;
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
            @media screen and (max-width: 992px) {
                &{
                    flex-direction: column;
                }
            }
            .propuesta-imagen{
                position: relative;
                height: 33vw;
                width: 49%;
                font-family: 'Montserrat', sans-serif;
                @media screen and (max-width: 992px) {
                    &{
                        width: 100%;
                        height: 70vw;
                    }
                }
                img{
                    width: 100%;
                    height: 100%;
                }
                .propuesta-imagen-filtro{
                    position: absolute;
                    top: 0;
                    left: 0;
                    background: #2E3191;
                    mix-blend-mode: multiply;
                    opacity: 0.7;
                    z-index: 200;
                    height: 100%;
                    width: 100%;
                }
                .propuesta-indice{
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 67px;
                    height: 67px;
                    background-color: #F37021;
                    z-index: 600;
                    color: white;
                    font-size: 3em;
                    @media screen and (max-width: 992px) {
                        &{
                            width: 50px;
                            height: 50px;
                            font-size: 2em;
                        }
                    }
                }

                .propuesta-titulo{
                    position: absolute;
                    bottom: 12%;
                    left: 14%;
                    color: white;
                    font-size: 2em;
                    font-weight: 600;
                    z-index: 600;
                    text-transform: uppercase;
                    letter-spacing: 0.5px;
                    width: 50%;
                    margin: 0;
                    span{
                        font-weight: 900;
                    }
                    @media screen and (max-width: 992px) {
                        &{
                            font-size: 1.5em;
                            width: 90%;
                        }
                    }
                }
            }
            .propuesta-datos{
                font-family: 'Montserrat', sans-serif;
                width: 51%;
                @media screen and (max-width: 992px) {
                    &{
                        width: 100%;
                    }
                }
                .propuesta-datos-cabecera{
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    color: white;
                    background-color: #F37021;
                    height: 33%;
                    padding: 25px 55px;
                    box-sizing: border-box;
                    @media screen and (max-width: 992px) {
                        &{
                            padding: 25px 20px;
                        }
                    }
                    h3{
                        font-size: 1.13em;
                        font-weight: 700;
                        margin: 0 0 18px 0;
                        @media screen and (max-width: 992px) {
                            &{
                                font-size: 1.2em;
                                text-align: center;
                            }
                        }
                    }
                    p{
                        font-size: 1vw;
                        font-weight: 500;
                        margin: 0;
                        @media screen and (max-width: 992px) {
                            &{
                                font-size: .9em;
                                text-align: center;
                            }
                        }
                    }
                }
                .propuesta-datos-contenido{
                    height: 67%;
                    padding: 40px 0 0 55px;
                    box-sizing: border-box;
                    @media screen and (max-width: 992px) {
                        &{
                            height: fit-content;
                            padding: 25px;
                        }
                    }
                    h3{
                        color: #2E3191;
                        font-size: 1.13em;
                        font-weight: 700;
                        margin: 0;
                        @media screen and (max-width: 992px) {
                            &{
                                font-size: 1.2em;
                                text-align: center;
                            }
                        }
                    }
                    ul{
                        padding-left: 20px;
                        color: #2E3191;
                        font-size: 1vw;
                        font-weight: 500;
                        margin: 1.5em 0 0 0;
                        width: 85%;
                        @media screen and (max-width: 992px) {
                            &{
                                font-size: .8em;
                                text-align: center;
                                margin:1.5em auto 0 auto;
                            }
                        }
                        & > * + * {
                            margin-top: 1.5em;
                        }
                    }
                }


            }
        }
    }
}
