.side-menu{
    position: fixed;
    top: -100vh;
    height: calc(59% - 67px);
    width: 100%;
    background-color: white;
    z-index: 900;
    transition: all .4s ease-in-out;
    opacity: 0;
    &.visible {
        opacity: 1;
        transform: translateY(calc(100vh + 67px));
    }
    ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        height: 100%;
        margin: 0;
        padding: 0;
        list-style: none;
        .link {
            position: relative;
            transition: clip-path 275ms ease;
            white-space: nowrap;
            font-family: 'Montserrat', sans-serif;
            font-weight: 600;
            font-size: 1em;
            &:hover span::before, &:focus span::before {
                clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
            }
            span {
                position: relative;
                display: inline-block;
                color: #003687;
                cursor: pointer;
                &::before {
                    position: absolute;
                    content: attr(data-content);
                    color: darken(#003687, 10%);
                    text-decoration: underline;
                    text-decoration-color: darken(#003687, 10%);
                    clip-path: polygon(0 0, 0 0, 0% 100%, 0 100%);
                    transition: clip-path 275ms ease;
                }
            }
        }
            
        span.line-through::before {
            text-decoration-line: line-through;
        }
        
    }
}

.overlay{
    position: fixed;
    top: 67px;
    width: 100%;
    height: calc(100% - 67px);
    background-color: rgba(0, 0, 0, .5);
    backdrop-filter: blur(5px);
    opacity: 0;
    transition: all .2s ease-in-out;
    z-index: -100;
    &.visible{
        opacity: 1;
        z-index: 890;
    }
}