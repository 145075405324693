footer{
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 157px;
    box-sizing: border-box;
    box-shadow: 0px 0px 15px 2px #05224E;
    z-index: 500;
    @media screen and (max-width: 992px) {
        &{
            flex-direction: column;
            height: 241px;
            align-items: center;
            justify-content: space-evenly;
            padding: 18px 0;
            box-sizing: unset;
        }
    }
    * > p{
        margin: 0;
        font-family: 'Montserrat', sans-serif;
        font-size: 1.13em;
    }

    .footer-logo{
        @media screen and (max-width: 992px) {
            &{
                order: 1;
                width: 200px;
            }
        }
    }

    .footer-copyright{
        font-weight: 500;
        letter-spacing: 0.5px;
        color: #333333;
        align-self: flex-end;
        margin-bottom: 48px;
        @media screen and (max-width: 992px) {
            &{
                order: 3;
                margin: 0 auto;
                text-align: center;
                font-size: .66em;
            }
        }
    }

    .footer-redes-propuestas{
        @media screen and (max-width: 992px) {
            &{
                order: 2;
            }
        }
        .footer-propuestas{
            font-weight: 700;
            letter-spacing: 0.5px;
            color: #F37021;
            margin-bottom: 23px;
            @media screen and (max-width: 992px) {
                &{
                    text-align: center;
                    margin-bottom: 11px;
                }
            }
        }
        .footer-redes{
            & > * + * {
                margin-left: 6px;
                @media screen and (max-width: 992px) {
                    &{
                        margin-left: 4px;
                    }
                }
            }
            .nombre{
                margin-left: 17px;
                @media screen and (max-width: 992px) {
                    &{
                        margin-left: 14px;
                        width: 138px;
                    }
                }
            }
        }
    }
}