@import "../vars.scss";
.images-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  @media ($ipad) {
    grid-template-columns: repeat(2, 1fr);
  }
  .uno {
    background-image: url("../../assets/img/uno.jpg");
    min-height: 217px;
    background-size: cover;
    @media ($ipad) {
      min-height: 116px;
    }
  }
  .dos {
    background-image: url("../../assets/img/dos.jpg");
    min-height: 217px;
    background-size: cover;
    @media ($ipad) {
      min-height: 116px;
    }
  }
  .tres {
    background-image: url("../../assets/img/tres.jpg");
    min-height: 217px;
    background-size: cover;
    @media ($ipad) {
      min-height: 116px;
    }
  }
  .cuatro {
    background-image: url("../../assets/img/cuatro.jpg");
    min-height: 217px;
    background-size: cover;
    @media ($ipad) {
      min-height: 116px;
    }
  }
  .cinco {
    background-image: url("../../assets/img/cinco.jpg");
    min-height: 217px;
    background-size: cover;
    @media ($ipad) {
      min-height: 116px;
    }
  }
  .seis {
    background-image: url("../../assets/img/seis.jpg");
    min-height: 217px;
    background-size: cover;
    @media ($ipad) {
      min-height: 116px;
    }
  }
  .siete {
    background-image: url("../../assets/img/siete.jpg");
    min-height: 217px;
    background-size: cover;
    @media ($ipad) {
      min-height: 116px;
    }
  }
  .ocho {
    background-image: url("../../assets/img/ocho.jpg");
    min-height: 217px;
    background-size: cover;
    @media ($ipad) {
      min-height: 116px;
    }
  }
}
