html, body{
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

nav{
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    height: 51px;
    max-height: 51px;
    width: 100%;
    background-color: white;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 1em;
    color: #003687;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    margin: 0;
    padding: .5em 0 .5em 0;
    z-index: 1000;
    div{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 83%;
        margin: 0 auto;
        @media screen and (max-width: 992px) {
            &{
                width: 90%;
            }
        }

        img{
            @media screen and (max-width: 992px) {
                &{
                    transform: scale(.8)
                }
            }
        }
        ul{
            display: flex;
            list-style: none;
            & > * + * {
                margin-left: 3em;
                @media screen and (max-width: 992px) {
                    &{
                        margin-left: 2em;
                    }
                }
            }
            @media screen and (max-width: 768px) {
                &{
                    flex-direction: column;
                    display: none;
                }
            }
            .link {
                position: relative;
                transition: clip-path 275ms ease;
                white-space: nowrap;
                @media screen and (max-width: 992px) {
                    &{
                        font-size: .8em;
                    }
                }
                &:hover span::before, &:focus span::before {
                    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
                }
                span {
                    position: relative;
                    display: inline-block;
                    color: #003687;
                    cursor: pointer;
                    &::before {
                        position: absolute;
                        content: attr(data-content);
                        color: darken(#003687, 10%);
                        text-decoration: underline;
                        text-decoration-color: darken(#003687, 10%);
                        clip-path: polygon(0 0, 0 0, 0% 100%, 0 100%);
                        transition: clip-path 275ms ease;
                    }
                }
            }
                
            span.line-through::before {
                text-decoration-line: line-through;
            }
            
        }
    }
}


#nav-icon3{
    width: 30px;
    height: 22px;
    position: relative;
    margin: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
    display: none;
    @media screen and (max-width: 768px) {
        &{
            display: block;
        }
    }
    span {
        display: block;
        position: absolute;
        height: 5px;
        width: 100%;
        background: #003687;
        border-radius: 5px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .25s ease-in-out;
        -moz-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: .25s ease-in-out;
        &:nth-child(1) {
            top: 0px;
        }
        &:nth-child(2), &:nth-child(3) {
            top: 9px;
        }
        &:nth-child(4) {
            top: 18px;
        }
    }
    &.open span:nth-child(1) {
        top: 18px;
        width: 0%;
        left: 50%;
    }
    &.open span:nth-child(2) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    &.open span:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }
    &.open span:nth-child(4) {
        top: 18px;
        width: 0%;
        left: 50%;
    }
}